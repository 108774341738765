import {
  AppPropManagerProps,
  ApplicationEventHandler,
  ApplicationEventType,
  ApplicationFormattedMessages,
  CommandTypes,
  InitOptions,
  JWTLoader,
  ProductNames,
} from '@app-types';
import { AppPropManager } from '@utils';
import { CheckoutAuthCertificateLens } from '@utils/CheckoutAuthCertificate/types';
import { CACError, DekoErrorInfoTypes } from '@utils/Errors/types';
import asyncAdapter from '@utils/asyncAdapter';
import { HandledErrors, UnhandledErrors } from '@utils/errors';
import { LocalStorageType } from '@utils/eligibilityBeaconPersistence/types';

interface WalletApiInterface {
  checkout: (cacLoader: JWTLoader) => Promise<string>;
  promotionalInfo: (value: number) => void;
  open: () => void;
  close: () => void;
  show: () => void;
  hide: () => void;
}

export default class WalletApi implements WalletApiInterface {
  private appProps: AppPropManagerProps;

  private subscribers: Array<ApplicationEventHandler>;

  constructor({ initToken, isStandaloneUI = false, ...options }: InitOptions) {
    this.subscribers = [];
    this.appProps = new AppPropManager({ ...options, onApplicationEvent: this.handleApplicationEvent, isStandaloneUI });
    this.init(initToken);
  }

  public handleApplicationEvent = (value: ApplicationEventType, data?: string): void =>
    this.subscribers.forEach((subscriber) => subscriber(value, data));

  private setAppError = (product: ProductNames, errorInfo: DekoErrorInfoTypes, throwErrorMessage?: HandledErrors): void => {
    this.appProps.error = [{ product, errors: [errorInfo] }];

    if (throwErrorMessage) {
      throw new Error(throwErrorMessage);
    }
  };

  private verifyCheckoutBasket = (): void => {
    const { authCertificate, initCertificate } = this.appProps;

    const [firstProduct]: ProductNames[] = (authCertificate || initCertificate)?.availableProducts || [];

    localStorage.setItem(LocalStorageType.CurrentProductName, firstProduct);

    if (authCertificate === undefined) {
      this.setAppError(firstProduct, {
        error: CACError.generic,
        info: { message: HandledErrors.CAC_LOADER },
      }, HandledErrors.CAC_LOADER);
    }

    if (authCertificate.basket === undefined) {
      throw new Error('invalid CAC');
    }
  };

  public loadCAC = async (authTokenLoader: JWTLoader): Promise<CheckoutAuthCertificateLens> => {
    const token = await asyncAdapter(authTokenLoader, HandledErrors.CAC_LOADER);

    if (token === undefined) throw new Error(UnhandledErrors.NO_CAC);

    this.appProps.setAuthToken(token, true);

    this.verifyCheckoutBasket();

    return this.appProps.authCertificate;
  };

  public checkout = async (cacLoader: JWTLoader): Promise<undefined | string> => {
    try {
      const {
        availableProducts,
        ineligibleProducts,
        hasAvailableProducts,
        isExpired,
        expiresOn,
        basketValue,
      } = await this.loadCAC(cacLoader);
      const firstProduct = availableProducts?.[0];

      if (basketValue === 0 && firstProduct === ProductNames.REVOLVING_CREDIT) {
        this.setAppError(firstProduct, {
          error: CACError.amountTooLow,
          info: { minAmount: 0.01 },
        }, HandledErrors.CHECKOUT_FAILED);
      }

      if (isExpired === true) {
        this.setAppError(firstProduct, { error: CACError.expired, info: { date: expiresOn } }, HandledErrors.EXPIRED);
      }

      if (ineligibleProducts.length > 0) {
        this.appProps.error = ineligibleProducts;

        throw new Error(HandledErrors.PRODUCT);
      }

      if (hasAvailableProducts) {
        try {
          return await new Promise<undefined | ApplicationEventType | string>((resolve, reject): void => {
            const subscribe = (value: ApplicationEventType | string, data: string): void => {
              switch (value) {
                case ApplicationEventType.SUCCESS:
                  resolve(data);
                  break;
                case ApplicationEventType.REFERRED:
                case ApplicationEventType.REDIRECT:
                  resolve(value);
                  break;
                case ApplicationEventType.FAIL:
                  reject(data);
                  break;
                default:
                  console.log(`Ignoring event: ${value}`);
              }
            };
            this.subscribers = [subscribe];
          });
        } catch (e) {
          if (e === ApplicationFormattedMessages.FAILED_REFERRED) {
            throw new Error(e);
          } else {
            throw new Error(HandledErrors.CHECKOUT_FAILED);
          }
        }
      }

      throw new Error(UnhandledErrors.UNKNOWN_ERROR);
    } catch (e) {
      if (Object.values(HandledErrors).indexOf(e.message) === -1) {
        this.setAppError(ProductNames.GENERIC, { error: CACError.generic, info: { message: e.message } });
      }

      throw new Error(e.message);
    }
  };

  public init = async (initTokenLoader: JWTLoader): Promise<void> => {
    this.appProps.initToken = await asyncAdapter(initTokenLoader, UnhandledErrors.INIT_TOKEN_LOADER);
  };

  public open = (): void => {
    this.appProps.onEmit({ type: CommandTypes.open });
  };

  public promotionalInfo = (value: number): void => {
    this.appProps.promotionalValue = Number((value / 100).toFixed(2));
    this.open();
  };

  public close = (): void => {
    this.appProps.error = undefined;
    this.appProps.onEmit({ type: CommandTypes.close });
  };

  public show = (): void => {
    this.appProps.onEmit({ type: CommandTypes.engage });
  };

  public hide = (): void => {
    this.appProps.onEmit({ type: CommandTypes.dismiss });
  };
}
