/* eslint-disable */

export const NewPayTerms = [
  3,
  6,
  9,
  12,
  18,
  24,
  36,
  48,
];

type ElementType < T extends ReadonlyArray < unknown > > = T extends ReadonlyArray<infer ElementType> ? ElementType : never
export type Term = ElementType<typeof NewPayTerms>


const NewPayApr = 21.9;

export interface CalculatorResult {
  monthlyInstalment: number;
  total: number;
  interestToPay: number;
}

const Calculator = {
  instalments: (purchaseAmount: number, term: number, apr: number = NewPayApr): CalculatorResult => {
    if (!NewPayTerms.includes(term)) {
      throw new Error(`Term must be one of accepted values: ${NewPayTerms.join(', ')}`);
    }

    //  === These calculations come directly from New Day. The formula comments are as provided by them. ===
    // =========================================================================================================== //

    // ((ROUNDDOWN((((((APR+0.0499)/100)+1)^(1/12))),5)-1)*12)*100
    const annualSimpleInterest = (((Math.floor((Math.pow((((apr + 0.0499) / 100) + 1), (1 / 12))) * 100000) / 100000) - 1) * 12) * 100;

    // p + (p x ia / 365 x 31)
    const startingPrincipal = +(purchaseAmount + (purchaseAmount * (annualSimpleInterest / 100) / 365 * 31)).toFixed(2);

    // ia x 30.5 / 365
    const monthlySimpleInterestRate = annualSimpleInterest / 100 * 30.5 / 365;

    // (sp x im x ((1 + im)^t))) / (((1 + im)^t)-1)
    const monthlyInstalment = +((startingPrincipal * monthlySimpleInterestRate * (Math.pow(1 + monthlySimpleInterestRate, term))) / (Math.pow((1 + monthlySimpleInterestRate), term) - 1)).toFixed(2);

    // EMI x t
    const total = +(monthlyInstalment * term).toFixed(2);

    return { monthlyInstalment, total, interestToPay: total - purchaseAmount };
  },
};

export default Calculator;
