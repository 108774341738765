import { ProductNames } from '@app-types';
import type { Offers } from '@utils/CheckoutAuthCertificate/types';
import getValidOffers from '@utils/getValidOffers';
import calculateBlackhorseInstalment from '@utils/calculateBlackhorseInstalment';

import LogosMap from '../logos';
import productFinanceInfo from './productFinanceInfo';

const newPayFinanceInfo = (offers: Offers[], amount: number) => {
  const basketAmount = amount / 100;
  const validOffers = getValidOffers(offers, amount);
  const instalments = validOffers.map((offer) => calculateBlackhorseInstalment(basketAmount, offer));
  const interestFreeInstalments = validOffers.filter((offer) => offer.apr === 0).map((offer) => calculateBlackhorseInstalment(basketAmount, offer));
  const { productLogo, eligibilityLogo } = LogosMap[ProductNames.NEWPAY];
  const tagFormat = (value: number) => value;

  return productFinanceInfo(instalments, interestFreeInstalments, amount, productLogo, tagFormat, eligibilityLogo);
};

export default newPayFinanceInfo;
