import Cookies from 'js-cookie';
import CookieValues from '@utils/Cookies/types';
import type { Instalment } from 'financeInfo/types';
import type { CalculatorResult } from '@utils/Calculator';

const sortByMonthlyInstalment = (a: { monthlyInstalment: number; }, b: { monthlyInstalment: number; }) => {
  if (a.monthlyInstalment < b.monthlyInstalment) {
    return -1;
  }
  if (a.monthlyInstalment > b.monthlyInstalment) {
    return 1;
  }
  return 0;
};

const productFinanceInfo = <T extends Instalment | CalculatorResult>(
  instalments: T[],
  interestFreeInstalments: T[],
  formattedValue: number,
  financeProductLogo: string,
  tagFormat: (value: number) => number,
  financeEligibilityLogo = 'https://assets.dekopay.com/eligibility-success.png',
  eligibleTagline = 'Eligibility check successful',
) => {
  let lowestInterestFreeOffer: number;
  let lowestInterestBearingOffer: number;
  let lowestInterestBearingOfferTotal: number;

  if (instalments.length !== 0) {
    if (interestFreeInstalments.length !== 0) {
      interestFreeInstalments.sort(sortByMonthlyInstalment);
      lowestInterestFreeOffer = interestFreeInstalments[0].monthlyInstalment;
    }
    instalments.sort(sortByMonthlyInstalment);
    const lowestInterestBearingOfferCalculation = instalments[0];

    lowestInterestBearingOffer = lowestInterestBearingOfferCalculation.monthlyInstalment;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    lowestInterestBearingOfferTotal = lowestInterestBearingOfferCalculation.total;
  }

  const monthlyInstalment = () => {
    if (lowestInterestFreeOffer) {
      return lowestInterestFreeOffer;
    }

    if (lowestInterestBearingOffer) {
      return lowestInterestBearingOffer;
    }

    return undefined;
  };

  const total = () => formattedValue;

  const tagline = (): string => {
    if (lowestInterestFreeOffer) {
      return `Get 0% interest from £${tagFormat(lowestInterestFreeOffer).toFixed(2)} per month`;
    }
    if (lowestInterestBearingOffer) {
      return `Instalment plans available from £${tagFormat(lowestInterestBearingOffer).toFixed(2)} per month`;
    }
    return 'Finance available';
  };

  const customerIsEligible: boolean = Cookies.get('EligibilityCheckStatus') === CookieValues.PREQUALIFICATION_ACCEPT || Cookies.get('EligibilityCheckStatus') === 'true';
  const financeEligibleLogo = financeEligibilityLogo;
  const baseFinanceInfo = {
    total: Math.floor(total()),
    monthlyInstalment: monthlyInstalment() ? Math.floor(monthlyInstalment()) : undefined,
    financeProductLogo,
    tagline: tagline(),
    cta: 'Learn more',
  };

  return {
    ...baseFinanceInfo,
    ...(customerIsEligible && {
      eligibleTagline,
      financeEligibleLogo,
    }),
  };
};

export default productFinanceInfo;
