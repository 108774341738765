import styled, { createGlobalStyle, css } from 'styled-components';
import { FontFace } from 'components-typescript-react/dist/fonts';
import { ThemedComponentProps } from '@app-types';
import reset from 'styled-reset';

export interface StyleResetProps {
  parentSelector: string;
}
export const StyleReset = createGlobalStyle<StyleResetProps>`
  ${({ parentSelector }) => parentSelector} {
    margin: 0;
    padding: 0;
    overflow: visible;

    ${reset}

    &, & * {
      box-sizing: border-box;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    input,
    button {
      font-family: inherit;
      font-size: inherit;
      outline: none;
      border: none;
      background-color: transparent;
      padding: 0;
    }

    button {
      cursor: pointer;
    }
  }
`;

export interface WalletOverlayProps {
  isOpen: boolean;
}
export const WalletOverlay = styled.div<WalletOverlayProps>`
  display: none;

  @media only screen and (min-width: 602px) {
    pointer-events: all;
    visibility: ${({ isOpen }): string => (isOpen ? 'visible' : 'hidden')};
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    overflow: visible;
    background-color: rgb(0, 0, 0);
    opacity: ${({ isOpen }) => (isOpen ? '.6' : '0')};
    transition: opacity 300ms ease, visibility 300ms ease;
    z-index: 2147483647;
    @keyframes fadeIn {
      0% {
        opacity: 0;
        visibility: hidden;
      }
    }
    animation: fadeIn 300ms ease;
  }
`;

export interface WalletWrapperProps {
  isVisible: boolean;
  isOpen: boolean;
  fonts: FontFace[];
}
export const WalletWrapper = styled.div<WalletWrapperProps>`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  max-width: 100%;
  overflow: hidden;
  overflow-y: auto;
  visibility: ${({ isVisible }): string => (isVisible ? 'visible' : 'hidden')};
  font-family: Regular, Helvetica, Arial, sans-serif;
  font-weight: ${({ theme }: ThemedComponentProps) => theme.font?.weight.regular};
  font-size: ${({ theme }: ThemedComponentProps) => theme.font?.size.base};
  line-height: ${({ theme }: ThemedComponentProps) => theme.font?.lineHeight.base};
  color: ${({ theme }: ThemedComponentProps) => theme.colors?.text.base};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &,
  & * {
    box-sizing: border-box;
  }

  & {
    ${({ fonts }) =>
      fonts.map(
        (font) => css`
          @font-face {
            font-family: '${font.fontFamily}';
            src: ${font.src.map((src) => `url(${src.url}) format('${src.format}')`).join(', ')};
            font-weight: ${font.weight};
          }
        `,
      )}
  }

  position: relative;
  height: 100%;
  width: 100%;
  z-index: 10000;
  background-color: ${({ theme }): string => theme.colors?.ui.background};
  transition: transform 600ms ease, height 0ms;
  transform: ${({ isOpen }): string => (isOpen ? 'translateY(0)' : 'translateY(calc(100%))')};

  @media only screen and (min-width: 602px) {
    height: ${({ isOpen }): string => (isOpen ? '100vh' : '0')};
    transform: none;
    transition: height 0ms ${({ isOpen }): string => (isOpen ? '0ms' : '600ms')};
    box-shadow: none;
  }
`;

interface StyledPluginContainer {
  isOpen: boolean;
  isHidden?: boolean;
  isStandaloneUI?: boolean;
  desktopMaxWidth?: string;
}

export const PluginContainer = styled.div<StyledPluginContainer>`
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: 1.5;
  z-index: 2147483647;
  min-width: 320px;
  display: ${({ isHidden }): string => (isHidden ? 'none' : 'block')};

  @media only screen and (max-width: 1023.5px) {
    ${({ isStandaloneUI }): string => isStandaloneUI && 'position: static;'}
  }

  @media only screen and (max-width: 601px) {
    height: ${({ isOpen }): string => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }): string => (isOpen ? '0' : 'auto')};
    left: 0;
    transition: height ${({ isOpen }): string => (isOpen ? '0ms' : '600ms')} ease;
  }

  @media only screen and (min-width: 602px) {
    max-width: ${({ desktopMaxWidth }): string => desktopMaxWidth || '400px'};
    top: 0;
    right: 0;
    ${({ isStandaloneUI, isOpen }): string =>
      !isStandaloneUI 
      && `
      transform: translateX(${isOpen ? '0' : '100%'});`}
    transition: transform 600ms ease;
  }
`;
