import { Atoms } from "components-typescript-react";
import React, { ChangeEvent, useState } from "react";
import { MockHeaderPickerProps } from "./types";
import { MockHeaderPickerWrapper, ToggleIconWrapper } from "./styles";

const IconHelp = Atoms.ICON_Help;
const DDListSelect = Atoms.DDLIST_select;

const MockHeaderPicker:React.FC<MockHeaderPickerProps> = ({mockHeaders}) => {
  const stateOptions = Object.entries(mockHeaders).map(([key, value]) => ({ description: key, const: value }));
  const [value, setValue] = useState((JSON.parse(sessionStorage.getItem('deko-mock-headers'))?.["DEKO-RESPONSE-ID"]) || stateOptions[0].const);
  const [visible, setVisible] = useState(value !== stateOptions[0].const);

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
    sessionStorage.setItem('deko-mock-headers', JSON.stringify({"DEKO-RESPONSE-ID": e.target.value}));
  };

  return (
    <MockHeaderPickerWrapper>
      <ToggleIconWrapper 
        data-testid="mock-header-toggle" 
        isVisible={visible} 
        onClick={() => setVisible((prev) => !prev)} 
        title={visible ? "Select the outcome you want from this journey using the dropdown" : "Click to select the outcome you want from this journey"} >
        <IconHelp />
      </ToggleIconWrapper>
      {visible 
        && <DDListSelect
          data-testid="mock-header-select"
          name='BNPP mock response status'
          options={stateOptions}
          onChange={onChange}
          value={value}/>
      }
    </MockHeaderPickerWrapper>
  );
};

export default MockHeaderPicker;