import formatMoneyInput from '@utils/FormatMoneyInput';
import { ProductNames } from '@app-types';
import { AppPropManager, InitCertificate } from '@utils';

import { FinanceArgs, FinanceInfo } from './types';
import revolvingCreditFinanceInfo from './implementations/revolvingCredit';
import monthlyInstalmentFinanceInfo from './implementations/monthlyInstalment';
import dekoMonthlyFinanceInfo from './implementations/dekoMonthly';
import multiLenderFinanceInfo from './implementations/multiLender';

import blackHorseFinanceInfo from './implementations/blackHorseFlexPay';
import newPayFinanceInfo from './implementations/newPay';

function getOffers(initToken: string, product: ProductNames) {
  if (AppPropManager.offers.length > 0) {
    return AppPropManager.offers;
  }

  if (initToken) {
    const cert = InitCertificate(initToken);
    const offers = cert.productOffers(product)
    return offers.filter((offer) => !offer.special);
  }

  return [];
}

const v2: FinanceInfo = (product, { value, initToken }: FinanceArgs) => {
  const valuePence = formatMoneyInput(value);

  if (valuePence <= 0) {
    return { message: 'Please enter a positive value' };
  }

  const offers = getOffers(initToken, product);

  switch (product) {
    case ProductNames.REVOLVING_CREDIT:
      return revolvingCreditFinanceInfo(offers, valuePence);
    case ProductNames.INSTALMENT:
      return monthlyInstalmentFinanceInfo(offers, valuePence);
    case ProductNames.DEKO_MONTHLY:
      return dekoMonthlyFinanceInfo(offers, valuePence);
    case ProductNames.BLACKHORSE_FLEXPAY:
      return blackHorseFinanceInfo(offers, valuePence);
    case ProductNames.NEWPAY:
      return newPayFinanceInfo(offers, valuePence);
    case ProductNames.MULTI_LENDER:
      return multiLenderFinanceInfo(offers, valuePence);
    case ProductNames.GENERIC:
    default:
      return { message: 'Please choose a valid financial product' };
  }
};

export default v2;
