import React from 'react';
import { Services, TileControl } from 'components-typescript-react';
import { TileControlRef } from 'components-typescript-react/dist/components/Tile/TileControl';
import Cookies from 'js-cookie';

import { ProductNames, TilePositions } from '@app-types';
import updateTileLenderByCookie from '@utils/Wallet';

export interface TileProps {
  firstAvailableProduct: ProductNames;
  isVisible: boolean;
  isOpen: boolean;
  tilePosition: TilePositions;
  handleOpen: () => void;
}

const Tile: React.FC<TileProps> = ({ firstAvailableProduct, isVisible, isOpen, tilePosition, handleOpen }) => {
  const tileRef = React.useRef<TileControlRef>();
  const creditLimitRef = React.useRef<number>(Cookies.get('EligibilityCheckLimit'));
  const [isTabMinimised, setIsTabMinimised] = React.useState(false);
  const [lowestMerchantApr, setLowestMerchantApr] = React.useState(null);
  const [lowestEligibleApr, setLowestEligibleApr] = React.useState(null);

  const eligibilityCredit = React.useCallback(() => {
    creditLimitRef.current = Cookies.get('EligibilityCheckLimit');
    if (tileRef.current && creditLimitRef.current) {
      tileRef.current.setEligibility(true, creditLimitRef.current);
    }
  }, []);

  React.useEffect(() => {
    tileRef.current?.visible?.(isVisible);
  }, [tileRef.current, isVisible]);

  React.useEffect(() => {
    if (tileRef.current) {
      switch (firstAvailableProduct) {
        case ProductNames.SPLIT:
          tileRef.current.changeService(Services.zip);
          break;
        case ProductNames.REVOLVING_CREDIT:
          tileRef.current.changeService(Services.newPay);
          break;
        case ProductNames.NEWPAY:
          if (tileRef.current) {
            tileRef.current.changeService(Services.blackhorseNewpay);
          }
          break;
        case ProductNames.BLACKHORSE_FLEXPAY:
          // clean up cookies from previous journey, if any, update when BE is ready.
          if (tileRef.current) {
            tileRef.current.changeService(Services.blackhorseFlexpay);
          }
          break;
        case ProductNames.INSTALMENT:
        case ProductNames.DEKO_MONTHLY:
        case ProductNames.MULTI_LENDER:
          tileRef.current.changeService(Services.instalment);
          break;
        case ProductNames.GENERIC:
        default:
          tileRef.current.changeService(Services.retailFinance);
          break;
      }
    }
  }, [firstAvailableProduct, tileRef.current]);

  React.useEffect(() => {
    if (!isOpen) eligibilityCredit();

    if (firstAvailableProduct === ProductNames.BLACKHORSE_FLEXPAY || firstAvailableProduct === ProductNames.NEWPAY) {
      updateTileLenderByCookie(setLowestEligibleApr, setLowestMerchantApr, tileRef, creditLimitRef);
    }
    if (!isTabMinimised && isOpen) setIsTabMinimised(true);
  }, [isOpen]);

  const onMessage = (evt: MessageEvent) => {
    switch (evt.data?.message) {
      case 'LOWEST_MERCHANT_OFFER':
        setLowestMerchantApr(evt.data?.lowestApr);
        break;
      case 'LOWEST_ELIGIBLE_OFFER':
        setLowestEligibleApr(evt.data?.lowestApr);
        break;
      default:
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return (
    <TileControl
      ref={tileRef}
      presetVisible={isVisible}
      presetTilePosition={tilePosition}
      lowestMerchantApr={lowestMerchantApr}
      lowestEligibleApr={lowestEligibleApr}
      onTileClick={handleOpen}
    />
  );
};

export default Tile;
