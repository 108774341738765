import React from 'react';
import { ZipPromotionalSplash } from 'components-typescript-react';

export interface ZipSplashWithProductProps {
  productAmount: number;
}

const ZipSplashWithProduct: React.FC<ZipSplashWithProductProps> = ({ productAmount }) => {
  const splitInstalments = React.useMemo(() => {
    const modulus = ((productAmount * 100) % 4) / 100;
    const instalments = [1, 2, 3, 4].map(() => productAmount / 4);

    instalments[0] += modulus;

    return instalments;
  }, [productAmount]);

  return <ZipPromotionalSplash amount={productAmount} instalments={splitInstalments} />;
};

export default ZipSplashWithProduct;
