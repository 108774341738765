import { ProductNames, SchemaObject } from '@app-types';
import { WorkflowType } from '@components/WorkflowStep/types';
import { CACError, DekoErrorInfo } from '@utils/Errors/types';
import { Restriction } from 'components-typescript-react';

export enum CACProductNames {
  split = 'split',
  revolvingCredit = 'revolving_credit',
  instalment = 'instalment',
  dekoMonthly = 'deko_monthly',
  blackHorseFlexPay = 'blackhorse-flexpay',
  blackHorseFlexPayStandalone = 'blackhorse-flexpay-standalone',
  newpay = 'newpay',
  newpayStandalone = 'newpay-standalone',
  multiLender = 'multi_lender',
}

export enum Currency {
  GBP = 'GBP',
}

export enum OfferSource {
  Promotional = 'Promotional',
  Product = 'Product',
}

export interface DekoMonthlyOfferFields {
  subsidyLower: number;
  subsidyUpper: number;
  p4lMinFee: number;
  lenderMinFee: number;
  lMin: number;
  lMax: number;
  factor: number;
  fees: number;
  roundRepayments: string;
  repaymentsSpread: string;
  loan_type: string;
}

export interface LenderOfferFields {
  productCode: string;
  creditProductType: string;
  creditProductCollectionType: string;
  apr: number;
  monthlyRate: number;
  minDeferredMonths: string;
  maxDeferredMonths: string;
  minTermMonths: string;
  maxTermMonths: string;
  minDeposit: number;
  minPurchaseAmount: number;
}

export interface OffersIllustration {
  monthlyPayment: {
    value: number;
    currencyCode: Currency;
  };
  interestToPay: {
    value: number | string;
    currencyCode: Currency;
  };
  totalRepayable: {
    value: number | string;
    currencyCode: Currency;
  };
}

export interface Offers {
  monthlyPayment?: number;
  value?: number;
  description: string;
  term: number;
  apr: number;
  sar?: number;
  promotional?: boolean;
  special?: boolean | number;
  id?: string;
  lender?: string;
  lenderOfferFields?: LenderOfferFields | DekoMonthlyOfferFields;
  restrictions?: Restriction[];
  offerSource?: OfferSource;
  illustration?: OffersIllustration;
  _links?: {
    self: {
      href: string;
    };
  };
}

interface Links {
  self: {
    href: string;
  };
  'product:apply': {
    href: string;
  };
}

export interface InitPayloadSplash {
  stepName: string;
  asset: string;
  type: WorkflowType;
  schema: SchemaObject;
}

interface Product {
  offers: Offers[];
  _links: Links;
  splash?: InitPayloadSplash;
}

export interface Products {
  [CACProductNames.split]?: {
    _links: Links;
  };
  [CACProductNames.revolvingCredit]?: Product;
  [CACProductNames.instalment]?: Product;
  [CACProductNames.blackHorseFlexPay]?: Product;
  [CACProductNames.newpay]?: Product;
}

export interface ValidationErrors {
  [CACProductNames.split]?: {
    [CACError.currencyNotSupported]?: {
      supportedCurrencies: Currency[];
    };
    [CACError.amountTooLow]?: {
      minAmount: number;
    };
    [CACError.amountTooHigh]?: {
      maxAmount: number;
    };
  };
  [CACProductNames.revolvingCredit]?: {
    [CACError.currencyNotSupported]?: {
      supportedCurrencies: Currency[];
    };
    [CACError.amountTooHigh]?: {
      maxAmount: number;
    };
    [CACError.basketItemNotSupported]?: {
      item: string;
    };
  };
}

export interface Basket {
  currency: 'GBP';
  total: string;
  items: {
    description: string;
    quantity: number;
    price: string;
    type: 'sku';
    imageUri: string;
    itemUri: string;
  }[];
}

export interface CheckoutAuthCertificateLens {
  hasAvailableProducts: boolean;
  availableProducts: ProductNames[];
  availableProduct: ProductNames;
  hasBasket: boolean;
  basket: Basket;
  ineligibleProducts: DekoErrorInfo[] | undefined;
  basketValue: number;
  getApplyUrl: (product: ProductNames) => string;
  expiresOn: Date | undefined;
  isExpired: boolean;
  customerInfo: CustomerInfo;
}

export interface BillingAddress {
  title: string;
  firstName: string;
  lastName: string;
  middleName: string;
  address1: string;
  address2: string;
  town: string;
  county: string;
  postcode: string;
  country: string;
}

interface ShippingAddress {
  title: string;
  firstName: string;
  lastName: string;
  middleName: string;
  address1: string;
  address2: string;
  town: string;
  county: string;
  postcode: string;
  country: 'GB';
}

export interface CustomerInfo {
  billingAddress: BillingAddress;
  shippingAddress?: ShippingAddress;
  phone: string;
  email: string;
  birthDate: string;
}

export interface CACPayload {
  iat: number;
  exp: number;
  iss: 'api.deko.finance' | 'api.dekopay.com';
  aud: 'api.deko.finance' | 'api.dekopay.com';
  jti: string;
  sub: string;
  name: string;
  claimKey: string;
  [key: `${string}/checkout`]: {
    customer: CustomerInfo;
    uniqueReference: string; // 'some-unique-reference-from-the-ecommerce-platform'
    basket: Basket;
  };
  [key: `${string}/product`]: Products;
  [key: `${string}/product/ineligible`]: ValidationErrors;
}
