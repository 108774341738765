import {
  ApiAdapter,
  Application,
  ApplicationStatus,
  CAC,
  CreateApplicationStatus,
  EnhancedApplicationStatusPayload,
  ProductNames,
  SchemaObject,
} from '@app-types';
import { CheckoutAuthCertificate } from '@utils/index';

import consent from './fixtures/consent.json';

class MockApiAdapter implements ApiAdapter {
  private status: ApplicationStatus;

  private product: string;

  constructor() {
    this.status = ApplicationStatus.pending;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createApplication = async (product: string, cac: CAC): Promise<Application> => {
    this.status = ApplicationStatus.actionRequired;
    this.product = product;
    const cacAccess = CheckoutAuthCertificate(cac);

    setTimeout(() => {
      this.status = cacAccess.basketValue === 123.45 ? ApplicationStatus.failure : ApplicationStatus.success;
    }, 5000);

    return {
      status: CreateApplicationStatus.success,
      pollUrl: 'https://example.com/api',
      pollInterval: 2000,
      appId: ''
    };
  };

  captureEvent = async () => {}

  sendPayload = async (_payload: object, _submit: string, cac: CAC): Promise<EnhancedApplicationStatusPayload> => {
    this.status = ApplicationStatus.actionRequired;

    const cacAccess = CheckoutAuthCertificate(cac);

    setTimeout(() => {
      this.status = cacAccess.basketValue === 123.45 ? ApplicationStatus.failure : ApplicationStatus.success;
    }, 5000);

    return {
      status: ApplicationStatus.pending,
      appId: '',
    };
  };

  getStatus = async () => {
    if (this.product === ProductNames.INSTALMENT && this.status === ApplicationStatus.actionRequired) {
      return {
        status: ApplicationStatus.actionRequired,
        url: 'http://localhost:3000',
        _links: {
          workflow: {
            submit: {
              uri: 'http://localhost:3000/submit',
            },
            jsonSchema: consent as SchemaObject,
          },
        },
      };
    }

    return (this.status === ApplicationStatus.actionRequired
      ? {
        status: this.status, url: 'https://www.deko.com', _links: {},
        }
      : { status: this.status });
  };
}

export default MockApiAdapter;
