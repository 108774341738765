import { Offers } from '@utils/CheckoutAuthCertificate/types';
import { ProductNames } from '@app-types';
import { ProductPayload } from '@utils/Token/getProductNames';

const getProductOffers = (payload: ProductPayload): Offers[] => {
  const productNames = payload[`${payload.claimKey}/product`];

  if (!productNames) return [];

  const productMapping: { [key: string]: string } = {
    [ProductNames.REVOLVING_CREDIT]: 'revolving_credit',
    [ProductNames.INSTALMENT]: 'instalment',
    [ProductNames.DEKO_MONTHLY]: 'deko_monthly',
    [ProductNames.MULTI_LENDER]: 'multi_lender',
    [ProductNames.BLACKHORSE_FLEXPAY]: 'blackhorse-flexpay',
    [ProductNames.BLACKHORSE_FLEXPAY_STANDALONE]: 'blackhorse-flexpay-standalone',
    [ProductNames.NEWPAY]: 'newpay',
    [ProductNames.NEWPAY_STANDALONE]: 'newpay-standalone',
  };
  const matchingProductKey = Object.keys(productMapping).find((productKey) => productNames[productKey]);

  if (matchingProductKey) {
    return productNames[productMapping[matchingProductKey]].offers || [];
  }
  return [];
};

export default getProductOffers;
