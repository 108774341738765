import { LenderName, ProductNames } from '@app-types';

const getLenderFromProduct = (product: ProductNames | LenderName): LenderName | undefined => {
  switch (product) {
    case ProductNames.BLACKHORSE_FLEXPAY:
    case ProductNames.BLACKHORSE_FLEXPAY_STANDALONE:
      return LenderName.BLACKHORSE;
    case ProductNames.NEWPAY:
    case ProductNames.NEWPAY_STANDALONE:
      return LenderName.NEWPAY;
    default:
      return undefined;
  }
};

export default getLenderFromProduct;
