import { createContext } from 'react';
import { ApiAdapter } from '@app-types';
import {PublicApiCapturedEventPayload} from "@utils/PublicApiAdapter/types";
import { ApiClientContextInterface, Listener } from '../types';

let apiAdapter: ApiAdapter;
let pollTimer: NodeJS.Timeout;
const listeners: Listener[] = [];

export const getListenerCount = () => listeners.length;

export const context: ApiClientContextInterface = {
  appId: '',
  async createApplication(product, cac) {
    const data = await apiAdapter.createApplication(product, cac);

    if ("appId" in data) {
      this.appId = data.appId;
    }

    return data;
  },
  subscribe: (listener) => {
    if (!listeners.includes(listener)) {
      listeners.push(listener);
    }
  },
  unsubscribe: (listener) => {
    const index = listeners.indexOf(listener);
    if (index >= 0) {
      listeners.splice(index, 1);
    }

    if (pollTimer) {
      clearInterval(pollTimer);
      pollTimer = null;
    }
  },
  startPolling(url, interval, cac) {
    if (pollTimer) clearInterval(pollTimer);
    pollTimer = setInterval(async () => {
      const status = await apiAdapter.getStatus(url, cac);
      listeners.forEach((listener) => {
        listener(status, this.appId);
      });
    }, interval);
  },
  async sendPayloadPost (payload: object, submit: string, cac: string) {
    const result = await apiAdapter.sendPayload(payload, submit, cac);

    this.appId = result?.appId;

    return result;
  },
  captureEvent: async (payload: PublicApiCapturedEventPayload, url: string, cac: string) => apiAdapter.captureEvent(payload, url, cac),
};

export const setApiAdapter = (adapter: ApiAdapter): void => {
  apiAdapter = adapter;
};

export const ApiClientContext = createContext(context);
