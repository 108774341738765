import { Services } from 'components-typescript-react';
import { TileControlRef } from 'components-typescript-react/dist/components/Tile/TileControl';
import Cookies from 'js-cookie';

import { CookieType } from '@utils/eligibilityBeaconPersistence/types';

const updateTileLenderByCookie = (
  setLowestEligibleApr: (apr: string) => void,
  setLowestMerchantApr: (apr: string) => void,
  tileRef: React.MutableRefObject<TileControlRef>,
  creditLimitRef: React.MutableRefObject<number>,
) => {
  // temporary solution, will be updated when BE is ready.
  setLowestMerchantApr('0');
  setLowestEligibleApr('0');

  const eligibilityCheckStatus = Cookies.get(CookieType.EligibilityCheckStatus);
  const eligibilityLender = Cookies.get(CookieType.EligibilityCheckLender);

  if (eligibilityCheckStatus === 'false') {
    tileRef.current?.visible(false);
    return;
  }

  const lenderServices = {
    blackhorse: Services.blackhorseFlexpay,
    newpay: Services.blackhorseNewpay,
  };

  Object.entries(lenderServices).forEach(([lender, service]) => {
    if (eligibilityLender === lender) {
      tileRef.current.changeService(service);
      if (eligibilityCheckStatus === 'true') {
        tileRef.current.setEligibility(true, creditLimitRef.current);
      }
    }
  });
};

export default updateTileLenderByCookie;
