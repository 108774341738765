enum MessageCommands {
  CLOSE_PLUGIN = 'CLOSE_PLUGIN',
  REQUEST_FORM_DATA = 'REQUEST_FORM_DATA',
  PREQUALIFICATION_ACCEPT = 'PREQUALIFICATION_ACCEPT',
  PREQUALIFICATION_DECLINE = 'PREQUALIFICATION_DECLINE',
  PREQUALIFICATION_PARTNER = 'PREQUALIFICATION_PARTNER',
  ESTIMATED_CREDIT_LIMIT = 'ESTIMATED_CREDIT_LIMIT',
  SEND_SUBMIT_DATA = 'SEND_SUBMIT_DATA',
  FRAME_READY = 'FRAME_READY'
}

export default MessageCommands;
