import { Buffer } from 'buffer';
import type { EnvironmentVariableContextInterface } from 'components-typescript-react/dist/components/Workflow/atoms/ENVCONTEXT/ENVCONTEXT';

import { Commands } from '@components/WorkflowStep/utils/constants';
import AppPropManager from '@utils/AppPropManager';
import { LocalStorageType } from '@utils/eligibilityBeaconPersistence/types';
import handleCookieUIs from '@utils/Cookies';
import { ProductNames, SchemaObject } from '@app-types';
import MessageCommands from '@utils/MessageListener/types';
import { encodeBase64 } from '@components/WorkflowStep/productInfo';
import { Currency, Offers } from '@utils/CheckoutAuthCertificate/types';
import getValidOffers from '@utils/getValidOffers';
import calculateBlackhorseInstalment from '@utils/calculateBlackhorseInstalment';

type IFrameRemoveEventListener = () => void;
type OnEventIFramePayload = { type: Commands; data: string };

export type OnLoadIFrameParams = {
  iFrame: HTMLIFrameElement;
  schema: SchemaObject;
  productAmount: number;
  environmentVariables: EnvironmentVariableContextInterface;
  originUrl: string;
};

const createInstalments = (productAmount: number): Offers[] =>
  getValidOffers(AppPropManager.offers, productAmount * 100).map((offer) => {
    const { interestToPay, monthlyInstalment, total } = calculateBlackhorseInstalment(productAmount, offer);

    return {
      ...offer,
      illustration: {
        monthlyPayment: { value: monthlyInstalment, currencyCode: Currency.GBP },
        totalRepayable: { value: total.toFixed(2), currencyCode: Currency.GBP },
        interestToPay: { value: interestToPay.toFixed(2), currencyCode: Currency.GBP },
      },
    };
  });

const createSchemaWithProduct = (schema: SchemaObject, productAmount: number): SchemaObject => ({
  ...schema,
  _page: schema._page === 'FlexPaySplashNoProduct' ? 'FlexPayEligibilitySplashWithProduct' : schema._page,
  meta: {
    ...schema.meta,
    productAmount,
    isShownWIQButton: AppPropManager.isShownWIQButton,
    offers: createInstalments(productAmount),
  },
});

type OnLoadMessage = {
  showCookieConsent: boolean;
  showCookieNoticeBanner: boolean;
  jsonSchema: SchemaObject;
  env: EnvironmentVariableContextInterface;
  cookieStatus: string;
  origin: string;
};

const createOnLoadMessage = (schema: SchemaObject, environmentVariables: EnvironmentVariableContextInterface): OnLoadMessage => ({
  jsonSchema: schema,
  env: environmentVariables,
  cookieStatus: localStorage.getItem(LocalStorageType.CookieStatus),
  origin: window.location.origin,
  ...handleCookieUIs(localStorage.getItem(LocalStorageType.CurrentProductName) as ProductNames, schema.lender),
});

const createOnLoadPayload = (message: OnLoadMessage): OnEventIFramePayload => ({
  type: Commands.ALL,
  data: Buffer.from(JSON.stringify(message)).toString('base64'),
});

export function onLoadIFrame({ iFrame, schema, productAmount, environmentVariables, originUrl }: OnLoadIFrameParams): IFrameRemoveEventListener {
  const onLoad = () => {
    const updatedSchema = createSchemaWithProduct(schema, productAmount);
    const message = createOnLoadMessage(updatedSchema, environmentVariables);
    const payload = createOnLoadPayload(message);

    iFrame.contentWindow.postMessage(payload, originUrl);
  };

  iFrame.addEventListener('load', onLoad);

  return () => iFrame.removeEventListener('load', onLoad);
}

type OnMessageIFrameParams = { iFrame: HTMLIFrameElement; originUrl: string };

const createOnMessagePayload = (originUrl: string): OnEventIFramePayload => ({
  type: Commands.OFFERS,
  data: encodeBase64({ offers: AppPropManager.offers, origin: originUrl }),
});

export function onMessageIFrame({ iFrame, originUrl }: OnMessageIFrameParams): IFrameRemoveEventListener {
  const subscription = (m: MessageEvent<{ message?: MessageCommands }>) => {
    if (m.data?.message === MessageCommands.FRAME_READY) {
      const payload = createOnMessagePayload(originUrl);
      iFrame.contentWindow.postMessage(payload, originUrl);
    }
  };

  window.addEventListener('message', subscription);
  iFrame.addEventListener('message', subscription);

  return () => {
    window.removeEventListener('message', subscription);
    iFrame.removeEventListener('message', subscription);
  };
}
