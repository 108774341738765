import { EventType } from '@app-types';
import type { PublicApiCapturedEventPayload } from '@utils/PublicApiAdapter/types';
import {
  TrackComponentRenderEventArgs,
  TrackEventArguments,
  TrackInteractionEventArgs,
  TrackPageViewEventArguments,
} from '@utils/Event/types';
import AppPropManager from '@utils/AppPropManager';

const trackEvent = ({ pageId, apiClient, url, token, isInsideCheckout, ...other }: TrackEventArguments) => {
  const { captureEvent, appId } = apiClient;
  if (AppPropManager.activeLender && pageId) {
    const payload: PublicApiCapturedEventPayload = {
      lender: AppPropManager.activeLender,
      pageId,
      createdAt: new Date().toISOString(),
      [isInsideCheckout ? 'orderId' : 'applicationId']: appId,
      ...other,
    };

    captureEvent(payload, `${url}/event`, token);
  }
};

export const trackInteractionEvent = (props: TrackInteractionEventArgs) => trackEvent({
  ...props,
  type: EventType.EVENT,
});

export const trackPageViewEvent = (props: TrackPageViewEventArguments) => trackEvent({
  ...props,
  type: EventType.PAGE_VIEW,
});

export const trackComponentRenderEvent = (props: TrackComponentRenderEventArgs) => trackEvent({
  ...props,
  type: EventType.COMPONENT,
});

export default trackEvent;
