import { CheckoutAuthCertificateLens, Offers } from '@utils/CheckoutAuthCertificate/types';
import { DekoWalletCheckoutError } from '@utils/Errors/types';
import { InitCertificateLens } from '@utils/InitCertificate';
import { ThemeInterface } from 'components-typescript-react';
import {PublicApiCapturedEventPayload} from "@utils/PublicApiAdapter/types";

export enum ApplicationStatus {
  pending = 'PENDING',
  actionRequired = 'ACTION_REQUIRED',
  referred = 'REFERRED',
  success = 'SUCCESS',
  failure = 'FAILURE',
}

export enum ApplicationEventType {
  FAIL = 'fail',
  REFERRED = 'referred',
  REDIRECT = 'redirected',
  SUCCESS = 'success',
  PREQUALIFICATION_ACCEPT = 'prequalification_accept',
}

export interface ApplicationSuccessPayload {
  status: ApplicationStatus.success;
  receipt?: ApplicationEventType | null;
}

export interface ApplicationPendingPayload {
  status: ApplicationStatus.pending;
}

export interface SchemaObject {
  _id?: string;
  $schema?: string;
  _page?: string;
  _pageId?: string;
  title?: string;
  lender?: LenderName;
  meta?: {
    continueButtonTitle?: string;
    additionalFooterInfo?: string[];
    offers?: Offers[];
    creditInfo?: {
      assumedCreditLimit: number;
      apr: number;
    };
    [key: string]: any;
  };
  type?: string;
  properties?: {
    customerInfo?: {
      type: string;
      properties: {
        firstName?: {
          type: string;
        };
        lastName?: {
          type: string;
        };
      };
    };
  };
  required?: string[];
}

export interface ApplicationActionPayload {
  status: ApplicationStatus.actionRequired;
  pollInterval?: number;
  url: string;
  _links?: {
    workflow?: {
      jsonSchema: SchemaObject;
      submit: {
        uri: string;
      };
      type?: string;
    };
  };
}

interface ApplicationEndStatusPayload {
  url?: string;
  _links?: {
    workflow?: {
      jsonSchema: SchemaObject;
      submit: {
        uri: string;
      };
      type?: string;
    };
  };
}

export interface ApplicationFailurePayload extends ApplicationEndStatusPayload {
  status: ApplicationStatus.failure;
  reason?: string;
}

export interface ApplicationReferredPayload extends ApplicationEndStatusPayload {
  status: ApplicationStatus.referred;
}

export type ApplicationStatusPayload =
  | ApplicationSuccessPayload
  | ApplicationFailurePayload
  | ApplicationPendingPayload
  | ApplicationReferredPayload
  | ApplicationActionPayload;

export type EnhancedApplicationStatusPayload = ApplicationStatusPayload & {appId: string};

export enum CreateApplicationStatus {
  success = 'SUCCESS',
  failure = 'FAILURE',
}

export interface CreateApplicationSuccess {
  status: CreateApplicationStatus.success;
  pollUrl: string;
  pollInterval: number;
  appId: string;
}

export interface CreateApplicationFailure {
  status: CreateApplicationStatus.failure;
}

export type Application = CreateApplicationSuccess | CreateApplicationFailure;

export interface ApiAdapter {
  createApplication: (product: ProductNames, cac: CAC) => Promise<Application>;
  getStatus: (url: string, cac: CAC) => Promise<ApplicationStatusPayload>;
  sendPayload: (payload: object, submitUrl: string, cac: CAC) => Promise<EnhancedApplicationStatusPayload>;
  captureEvent: (payload: PublicApiCapturedEventPayload, url: string, cac: CAC) => Promise<void>;
}

// NOSONAR
export type CAC = string;

export type JWTLoader = () => Promise<string>;

// NOSONAR
export type InitToken = string;

export type InitTokenLoader = () => Promise<string>;

// NOSONAR
export type Certificate = string;

export enum CommandTypes {
  close = 'close',
  soft_close = 'soft_close', // this won't use onClose function passed to the wallet on dekoWallet.init() function.
  open = 'open',
  dismiss = 'dismiss',
  engage = 'engage',
  clearPromotional = 'clearPromotional',
}

export interface Command {
  type: CommandTypes;
}

export type ApplicationEventHandler = (value: ApplicationEventType, data?: string) => void;

export type OnEmit = (command: Command) => void;

export interface AppPropManagerProps {
  authToken: string;
  readonly authCertificate?: CheckoutAuthCertificateLens;
  initToken: string;
  readonly initCertificate?: InitCertificateLens;
  isOpen: boolean;
  promotionalValue?: number;
  onEmit: OnEmit;
  error?: DekoWalletCheckoutError;
  setAuthToken(token: string, isOpen?: boolean): void;
}

export interface AppProps extends Omit<AppPropManagerProps, 'setAuthToken'> {
  rootSelector: string;
  isVisible: boolean;
  tilePosition?: TilePositions;
  firstAvailableProduct: ProductNames;
  onApplicationEvent?: ApplicationEventHandler;
  onClose?: () => void;
  isStandaloneUI?: boolean;
}

export enum ProductNames {
  SPLIT = 'split',
  REVOLVING_CREDIT = 'revolving_credit',
  GENERIC = 'generic',
  INSTALMENT = 'instalment',
  DEKO_MONTHLY = 'deko_monthly',
  NEWPAY = 'newpay',
  NEWPAY_STANDALONE = 'newpay-standalone',
  BLACKHORSE_FLEXPAY = 'blackhorse-flexpay',
  BLACKHORSE_FLEXPAY_STANDALONE = 'blackhorse-flexpay-standalone',
  MULTI_LENDER = 'multi_lender',
}

export enum LenderName {
  BLACKHORSE = 'blackhorse',
  NEWPAY = 'newpay',
}

export enum EventType {
  PAGE_VIEW = 'PageView',
  EVENT = 'Event',
  COMPONENT = 'Component',
}

export type WalletProps = {
  firstAvailableProduct: ProductNames;
  onChangeActiveLender: (e: LenderName) => void;
};

export interface ThemedComponentProps {
  theme: ThemeInterface;
}

export enum TilePositions {
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_MIDDLE = 'bottom-middle',
  MIDDLE_RIGHT = 'middle-right',
}

export type InitOptions = {
  isVisible?: boolean;
  tilePosition?: TilePositions;
  onClose?: () => void;
  isStandaloneUI?: boolean;
  initToken: JWTLoader;
};

export type AppPropManagerOptions = Omit<InitOptions, 'initToken'> & {
  onApplicationEvent: (event: ApplicationEventType) => void;
  isStandaloneUI?: boolean;
};

export enum ApplicationReasons {
  REFERRED = 'referred',
}

export enum ApplicationFormattedMessages {
  FAILED_REFERRED = 'failed:referred',
}
