import { ProductNames } from '@app-types';
import { CACProductNames } from '@utils/CheckoutAuthCertificate/types';

const productToCACProduct = (product: ProductNames): CACProductNames => {
  switch (product) {
    case ProductNames.SPLIT:
      return CACProductNames.split;
    case ProductNames.REVOLVING_CREDIT:
      return CACProductNames.revolvingCredit;
    case ProductNames.INSTALMENT:
      return CACProductNames.instalment;
    case ProductNames.BLACKHORSE_FLEXPAY:
      return CACProductNames.blackHorseFlexPay;
    case ProductNames.BLACKHORSE_FLEXPAY_STANDALONE:
      return CACProductNames.blackHorseFlexPayStandalone;
    case ProductNames.NEWPAY:
      return CACProductNames.newpay;
    case ProductNames.NEWPAY_STANDALONE:
      return CACProductNames.newpayStandalone;
    case ProductNames.DEKO_MONTHLY:
      return CACProductNames.dekoMonthly;
    case ProductNames.MULTI_LENDER:
      return CACProductNames.multiLender;
    default:
      throw new Error(`Invalid product: ${product}`);
  }
};

export default productToCACProduct;
