import {
  Application, ApplicationStatusPayload, CAC, ProductNames,
} from '@app-types';
import {PublicApiCapturedEventPayload} from "@utils/PublicApiAdapter/types";

export type Listener = (status: ApplicationStatusPayload, appId: string) => void | Promise<void>;

export interface ApiClientContextInterface {
  readonly appId: string; 
  createApplication: (product: ProductNames, cac: CAC) => Promise<Application>;
  sendPayloadPost: (payload: object, submit: string, cac: CAC) => Promise<any>;
  subscribe: (listener: Listener) => void;
  unsubscribe: (listener: Listener) => void;
  startPolling: (url: string, interval: number, cac: CAC) => void;
  captureEvent: (payload: PublicApiCapturedEventPayload, url: string, cac: CAC) => Promise<void>;
}

export enum AdapterVersion {
  mock = 'mock',
  live = 'live',
}
