import type { Instalment } from 'financeInfo/types';
import type { Offers } from '../CheckoutAuthCertificate/types';

function getValidOffers<T extends Instalment | Offers>(offers: T[], basketAmount: number): T[] {
  return offers?.filter(({ restrictions }) => !restrictions?.some((restriction) => {
    if (!restriction) {
      return false;
    }
    switch (restriction.restrictionType) {
      case 'MAXIMUM_BASKET_AMOUNT':
        return basketAmount > Number(restriction.value) * 100;
      case 'MINIMUM_BASKET_AMOUNT':
        return basketAmount < Number(restriction.value) * 100;
      default:
        return false;
    }
  }));
}

export default getValidOffers;
