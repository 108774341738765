import { useRef } from 'react';

import { SchemaObject } from '@app-types';

const useWorkflowState = () => {
  const submitAction = useRef<string>(null);
  const workflowUrl = useRef<string>(null);
  const workflowSchema = useRef<SchemaObject>(null);
  const workflowOrigin = useRef<string>(null);
  const workflowType = useRef<string>(null);
  const workflowPollInterval = useRef<number>(null);

  return {
    submitAction,
    workflowUrl,
    workflowSchema,
    workflowOrigin,
    workflowType,
    workflowPollInterval,
  };
};

export default useWorkflowState;
