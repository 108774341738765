import React from 'react';
import PropTypes from 'prop-types';

interface GenericErrorPageProps {
  heading: string;
  body: string;
}

const GenericErrorPage: React.FC<GenericErrorPageProps> = ({ heading, body }) => (
  <>
    <strong>{heading}</strong>
    <p>{body}</p>
  </>
);

GenericErrorPage.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
};

GenericErrorPage.defaultProps = {
  heading: 'An error has occurred',
  body: 'Please retry later',
};

export default GenericErrorPage;
