import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { MockApiAdapter, PublicApiAdapter } from '@utils';
import { ApiClientContext, context, setApiAdapter } from '../instances';
import { AdapterVersion } from '../types';

interface ApiClientProviderProps {
  children: React.ReactNode;
  adapter?: AdapterVersion;
}

const ApiClientProvider: React.FC<ApiClientProviderProps> = ({
  children,
  adapter,
}) => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    switch (adapter) {
      case AdapterVersion.live:
        setApiAdapter(new PublicApiAdapter());
        setIsReady(true);
        break;
      case AdapterVersion.mock:
      default:
        setApiAdapter(new MockApiAdapter());
        setIsReady(true);
    }
  }, [adapter]);

  return (
    <ApiClientContext.Provider value={context}>
      {isReady ? children : null}
    </ApiClientContext.Provider>
  );
};

ApiClientProvider.propTypes = {
  adapter: PropType.oneOf(Object.values(AdapterVersion)),
  children: PropType.node.isRequired,
};

ApiClientProvider.defaultProps = {
  adapter: AdapterVersion.live,
};

export default ApiClientProvider;