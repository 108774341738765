import { ProductNames } from '@app-types';
import { CACPayload } from '@utils/CheckoutAuthCertificate/types';
import { InitPayload } from '@utils/InitCertificate/types';
import Cookies from 'js-cookie';
import { CookieType } from '@utils/eligibilityBeaconPersistence/types';
import productToCACProduct from './productToCACProduct';

const getApplyUrlForProduct = (payload: CACPayload | InitPayload, product: ProductNames, cookies = Cookies): string => {
  let financeFirstEnabled: boolean = false;
  if(product === ProductNames.REVOLVING_CREDIT) {
    if (`${payload.claimKey}/product` in payload && 'env' in payload && payload.env != null ) {
      financeFirstEnabled = payload.env?.featureFlags?.['seas-516-finance-first-enabled'];
      if( financeFirstEnabled != null) {
        cookies.set(CookieType.FinanceFirstEnabled, financeFirstEnabled);
      }
    }
  }
  
  return payload[`${payload.claimKey}/product`][productToCACProduct(product)]._links['product:apply'].href;
};

export default getApplyUrlForProduct;
