import React from 'react';
import Cookie from 'js-cookie';
import type { EnvironmentVariableContextInterface } from 'components-typescript-react/dist/components/Workflow/atoms/ENVCONTEXT/ENVCONTEXT';

import type { InitCertificateLens } from '@utils/InitCertificate';
import { Iframe } from '@components/WorkflowStep/styles';
import { LenderName, ProductNames } from '@app-types';
import { CookieType } from '@utils/eligibilityBeaconPersistence/types';
import createIFrameData, { IFrameData } from '@utils/createIFrameData';

import SplashIFrame from './styles';
import { onLoadIFrame, onMessageIFrame } from './utils';
import ZipSplashWithProduct from './ZipSplashWithProduct';

export interface SplashWithProductProps {
  initCertificate: InitCertificateLens;
  productAmount: number;
  environmentVariables: EnvironmentVariableContextInterface;
  selectedProduct: ProductNames;
}

const SplashWithProduct: React.FC<SplashWithProductProps> = ({ initCertificate, productAmount, environmentVariables, selectedProduct }) => {
  const frameRef = React.useRef<HTMLIFrameElement>(null);

  const product = React.useMemo<ProductNames>(() => {
    const isNewPayLender = Cookie.get(CookieType.EligibilityCheckLender) === LenderName.NEWPAY;
    return isNewPayLender ? ProductNames.NEWPAY : selectedProduct;
  }, [selectedProduct]);

  const iFrameData = React.useMemo<IFrameData>(
    () => product !== ProductNames.SPLIT && createIFrameData({ initCertificate, selectedProduct: product, productAmount }),
    [product],
  );

  React.useEffect(() => {
    if (frameRef.current) {
      if (product === ProductNames.NEWPAY || product === ProductNames.BLACKHORSE_FLEXPAY) {
        return onLoadIFrame({
          iFrame: frameRef.current,
          schema: initCertificate.getSplash(product).schema,
          environmentVariables,
          originUrl: iFrameData.originUrl,
          productAmount,
        });
      }

      return onMessageIFrame({ iFrame: frameRef.current, originUrl: iFrameData.originUrl });
    }
  }, [frameRef.current, iFrameData, environmentVariables, product, productAmount]);

  if (selectedProduct === ProductNames.SPLIT) {
    return <ZipSplashWithProduct productAmount={productAmount} />;
  }

  return (
    <SplashIFrame
      as={Iframe}
      data-testid="splash-with-product-iframe"
      title={iFrameData.schema?.title}
      key={iFrameData.url}
      src={iFrameData.url}
      ref={frameRef}
    />
  );
};

export default SplashWithProduct;
