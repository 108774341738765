import { useContext } from 'react';
import { ApiClientContextInterface } from './types';
import { ApiClientContext } from './instances';

export { default as ApiClientProvider } from './ApiClientProvider';

export { AdapterVersion } from './types';

export const useApiClient = () => useContext<ApiClientContextInterface>(ApiClientContext);

export default ApiClientContext;
