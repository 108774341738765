import { CACProductNames, InitPayloadSplash, Offers } from '@utils/CheckoutAuthCertificate/types';

export interface ProductPayload {
  claimKey: string;
  [key: `${string}/product`]: {
    [CACProductNames.revolvingCredit]?: {
      offers: Offers [],
      _links: {
        self: {
          href: string,
        },
        ['product:apply']: {
          href: string,
        }
      }
    },
    [CACProductNames.instalment]?: {
      offers: Offers [],
      _links: {
        self: {
          href: string,
        },
        ['product:apply']: {
          href: string,
        }
      }
    },
    [CACProductNames.dekoMonthly]?: {
      offers: Offers [],
      _links: {
        self: {
          href: string,
        },
        ['product:apply']: {
          href: string,
        }
      }
    },
    [CACProductNames.newpay]?: {
      offers: Offers[],
      splash?: InitPayloadSplash;
      _links: {
        self: {
          href: string,
        },
        ['product:apply']: {
          href: string,
        }
      }
    },
    [CACProductNames.split]?: object;
    [CACProductNames.blackHorseFlexPay]?: {
      offers: Offers[];
      splash?: InitPayloadSplash;
      _links: {
        self: {
          href: string;
        };
        ['product:apply']: {
          href: string;
        };
      };
    };
  }
}

const getProductNames = (payload: ProductPayload): CACProductNames[] => {
  if (!payload[`${payload.claimKey}/product`]) return [];
  return (
    Object.keys(payload[`${payload.claimKey}/product`]) as CACProductNames[]
  );
};

export default getProductNames;
