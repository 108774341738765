import { Term } from '@utils/Calculator';
import { ProductNames } from '@app-types';
import { Restriction } from 'components-typescript-react';

export interface FinanceArgs {
  value: number; // retailers should always pass pence to Deko
  term?: Term;
  initToken?: string;
}

export type FinanceResponse = FinanceExampleResponse | FinanceExampleResponseError;

export interface FinanceExampleResponseError {
  message: string;
}

export interface FinanceExampleResponse {
  monthlyInstalment: number;
  total: number;
  tagline?: string;
  cta?: string;
  financeProductLogo?: string;
  eligibleTagline?: string;
  financeEligibleLogo?: string;
}

export enum VERSION {
  v1 = 'v1.0',
  v2 = 'v2.0',
}

export type FinanceInfo = (product: ProductNames, args: FinanceArgs) => FinanceResponse;

export interface Instalment {
  monthlyInstalment: number;
  total: number;
  apr: number;
  restrictions: Restriction[];
  interestToPay?: number;
}

export interface MultiLender {
  monthlyInstalment: number;
  total: number;
  apr: number;
  restrictions: Restriction[];
}
