import { ProductNames } from '@app-types';
import { CACPayload, ValidationErrors } from '@utils/CheckoutAuthCertificate/types';

import {
  CACError,
  DekoWalletCheckoutError,
  DekoErrorInfoTypes,
} from '@utils/Errors/types';

import { InitPayload } from '@utils/InitCertificate/types';
import penceToDecimal from '@utils/Token/penceToDecimal';

const transformErrorInfo = (productErrors: ValidationErrors, errorKey: string): DekoErrorInfoTypes => {
  const cacError: string = errorKey as CACError;
  switch (cacError) {
    case CACError.amountTooHigh:
      return {
        error: CACError.amountTooHigh,
        info: {
          maxAmount: penceToDecimal(productErrors[CACError.amountTooHigh].maxAmount),
        },
      };
    case CACError.amountTooLow:
      return {
        error: CACError.amountTooLow,
        info: {
          minAmount: penceToDecimal(productErrors[CACError.amountTooLow].minAmount),
        },
      };
    case CACError.basketItemNotSupported:
      return {
        error: CACError.basketItemNotSupported,
        info: {
          item: productErrors[CACError.basketItemNotSupported].item,
        },
      };
    case CACError.currencyNotSupported:
      return {
        error: CACError.currencyNotSupported,
        info: {
          supportedCurrencies: productErrors[CACError.currencyNotSupported].supportedCurrencies,
        },
      };
    default:
      return {
        error: CACError.generic,
        info: {
          message: 'something went wrong...',
        },
      };
  }
};

const ineligibleProducts = (payload: CACPayload | InitPayload): DekoWalletCheckoutError => {
  const validationErrors: ValidationErrors = payload[`${payload.claimKey}/product/ineligible`];

  if (!validationErrors) {
    return [];
  }

  const invalidatedProducts: string[] = Object.keys(validationErrors);

  const errorCount: number = invalidatedProducts
    .map((p: ProductNames) => validationErrors[p].length)
    .reduce((acc: number, cur: number) => cur + acc, 0);

  if (errorCount === 0) {
    return [];
  }

  return invalidatedProducts.map((invalidProduct: ProductNames) => {
    const productErrors: ValidationErrors = validationErrors[invalidProduct];
    const errors = Object.keys(productErrors);

    const mappedErrors = errors.map<DekoErrorInfoTypes>(
      transformErrorInfo.bind(null, productErrors),
    );

    return {
      errors: mappedErrors,
      product: invalidProduct,
    };
  });
};

export default ineligibleProducts;
