import { ProductNames } from '@app-types';
import { CookieType } from '@utils/eligibilityBeaconPersistence/types';
import Cookies from 'js-cookie';

import { FinanceArgs, FinanceResponse, VERSION } from './types';
import v1 from './v1';
import v2 from './v2';



const financeInfo = async (product: ProductNames, { value, initToken, term }: FinanceArgs, version = VERSION.v1): Promise<FinanceResponse> => {
  const financeProduct: ProductNames = Cookies.get(CookieType.EligibilityCheckLender) || product;

  switch (version) {
    case VERSION.v2:
      return v2(financeProduct, { value, initToken });
    case VERSION.v1:
    default:
      return v1(financeProduct, { value, initToken, term });
  }
};

export default financeInfo;
