/* eslint-disable no-case-declarations */
import { ProductNames } from '@app-types';
import Calculator from '@utils/Calculator';
import { InitCertificate } from '@utils';
import Cookies from 'js-cookie';
import CookieValues from '@utils/Cookies/types';
import { FinanceArgs, FinanceInfo } from './types';

const v1: FinanceInfo = (product, { value, initToken, term }: FinanceArgs) => {
  const formattedValue = parseInt((value / 100).toFixed(2), 10);

  if (formattedValue <= 0) { return { message: 'Please enter a positive value' }; }

  if (!initToken) {
    // providing a term without init token will get the calculation for Newpay standard rates.
    // This is deprecated and should be removed at some point
    switch (product) {
      case ProductNames.REVOLVING_CREDIT: {
        if (!term) {
          return { message: 'A term is required for this financial product' };
        }
        const newpayCalculation = Calculator.instalments(formattedValue, term);
        return {
          total: newpayCalculation.total * 100,
          monthlyInstalment: newpayCalculation.monthlyInstalment * 100,
          message: 'Passing a term is deprecated. Please refer to the deko integration guide or speak to your account manager',
        };
      }
      case ProductNames.GENERIC:
      default:
        return { message: 'Please choose a valid financial product' };
      // ---------------------------------------------------------------------------------------------------------------
    }
  }

  const initTokenAccess = InitCertificate(initToken);
  const offers = initTokenAccess.offers.filter((offer) => !offer.special);
  switch (product) {
    case ProductNames.REVOLVING_CREDIT:
      const installments = offers.map((offer) => Calculator.instalments(value, offer.term, offer.apr));
      const interestFreeInstallments = offers.filter((offer) => offer.apr === 0)
        .map((offer) => Calculator.instalments(value, offer.term, offer.apr));

      const sortByMonthlyInstalment = (a, b) => {
        if (a.monthlyInstalment < b.monthlyInstalment) {
          return -1;
        }
        if (a.monthlyInstalment > b.monthlyInstalment) {
          return 1;
        }
        return 0;
      };
      const lowestInterestFreeOffer = interestFreeInstallments.sort(sortByMonthlyInstalment)[0];
      const lowestInterestBearingOffer = installments.sort(sortByMonthlyInstalment)[0];

      const total = () => {
        if (lowestInterestFreeOffer) {
          return value;
        }

        if (lowestInterestBearingOffer) {
          return lowestInterestBearingOffer.total;
        }

        return value;
      };

      const tagline = (): string => {
        if (lowestInterestFreeOffer) {
          return `Get 0% interest from £${lowestInterestFreeOffer.monthlyInstalment.toFixed(2)} per month`;
        }
        if (lowestInterestBearingOffer) {
          return `Instalment plans available from £${lowestInterestBearingOffer.monthlyInstalment.toFixed(2)} per month`;
        }
        return 'Finance available';
      };

      const monthlyInstalment = () => {
        if (lowestInterestFreeOffer) {
          return lowestInterestFreeOffer.monthlyInstalment;
        }

        if (lowestInterestBearingOffer) {
          return lowestInterestBearingOffer.monthlyInstalment;
        }

        return undefined;
      };

      const customerIsEligible: boolean = Cookies.get('EligibilityCheckStatus') === CookieValues.PREQUALIFICATION_ACCEPT;

      const baseFinanceInfo = {
        total: Math.floor(total() * 100),
        monthlyInstalment: monthlyInstalment() ? Math.floor(monthlyInstalment() * 100) : undefined,
        financeProductLogo: 'https://assets.dekopay.com/Logo/Lender/newpay.png',
        tagline: tagline(),
        cta: 'Learn more',
        message: 'version 1 has been deprecated. Please refer to https://docs.dekopay.com/v4.3/docs/quick-start on instructions on how to update.',
      };

      return ({
        ...baseFinanceInfo,
        ...(customerIsEligible && {
          eligibleTagline: 'Eligibility check successful',
          financeEligibleLogo: 'https://assets.dekopay.com/eligibility-success.png',
        }),
      });
    case ProductNames.GENERIC:
    default:
      return { message: 'Please choose a valid financial product' };
  }
};

export default v1;
