import type { RepresentativeExampleData } from 'components-typescript-react';

import { type InitToken, ProductNames } from '@app-types';
import decode from '@utils/Token/decode';
import getProductNames from '@utils/Token/getProductNames';
import transformToProductNames from '@utils/Token/transformToProductNames';
import type { InitPayload } from '@utils/InitCertificate/types';
import getProductOffers from '@utils/Token/getProductOffers';
import type { InitPayloadSplash, Offers } from '@utils/CheckoutAuthCertificate/types';
import getApplyUrlForProduct from '@utils/Token/getApplyUrlForProduct';
import getFirstAvailableProduct from '@utils/getAvailableProduct';

export interface InitCertificateLens {
  retailerName: string;
  hasAvailableProducts: boolean;
  availableProducts: ProductNames[];
  availableProduct: ProductNames;
  retailerId: string;
  getProductUrl: (product: ProductNames) => string;
  getSplash: (product: ProductNames) => InitPayloadSplash;
  productOffers: (product: ProductNames) => Offers[];
  eventApiUrl: string;
  offers: Offers[];
  authToken?: string;
  repExamples: RepresentativeExampleData[];
  env: { [key: string]: string };
  isStandAlone?: boolean;
}

const InitCertificate = (token: InitToken): InitCertificateLens => {
  try {
    const payload = decode<InitPayload>(token);
    const products = getProductNames(payload);
    const offers = getProductOffers(payload);

    return {
      get retailerName() {
        return payload.name;
      },
      get availableProducts() {
        return transformToProductNames(products);
      },
      get availableProduct() {
        return getFirstAvailableProduct(this.availableProducts);
      },
      get hasAvailableProducts() {
        return products.length > 0;
      },
      get offers() {
        return offers.length > 0 ? offers : this.getSplash(this.availableProduct)?.schema?.meta?.offers || [];
      },
      get retailerId() {
        return payload.sub;
      },
      get repExamples() {
        return payload['https://www.dekopay.com/terms-of-service']?.repExamples;
      },
      get env() {
        return payload.env;
      },
      get authToken() {
        return payload.app;
      },
      get isStandAlone() {
        return payload?.standalone;
      },
      get eventApiUrl() {
        return payload.aud
      },
      getSplash(product: ProductNames) {
        return payload[`${payload.claimKey}/product`][product]?.schema;
      },
      productOffers(product: ProductNames) {
        return offers.length > 0 ? offers : this.getSplash(product)?.schema?.meta?.offers || [];
      },
      getProductUrl: (product) => getApplyUrlForProduct(payload, product),
    };
  } catch (e) {
    throw new Error('Invalid init token 😢');
  }
};

export default InitCertificate;
