import { CACProductNames } from '@utils/CheckoutAuthCertificate/types';
import { ProductNames } from '@app-types';

const cacProductTransform = (product: CACProductNames): ProductNames => {
  switch (product) {
    case CACProductNames.revolvingCredit:
      return ProductNames.REVOLVING_CREDIT;
    case CACProductNames.split:
      return ProductNames.SPLIT;
    case CACProductNames.instalment:
      return ProductNames.INSTALMENT;
    case CACProductNames.blackHorseFlexPay:
      return ProductNames.BLACKHORSE_FLEXPAY;
    case CACProductNames.blackHorseFlexPayStandalone:
      return ProductNames.BLACKHORSE_FLEXPAY_STANDALONE;
    case CACProductNames.dekoMonthly:
      return ProductNames.DEKO_MONTHLY;
    case CACProductNames.newpay:
      return ProductNames.NEWPAY;
    case CACProductNames.newpayStandalone:
      return ProductNames.NEWPAY_STANDALONE;
    case CACProductNames.multiLender:
      return ProductNames.MULTI_LENDER;
    default:
      throw new Error(`Invalid product: ${product}`);
  }
};

export default cacProductTransform;
