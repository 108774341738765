import {CAC} from '@app-types';
import jwt_decode from 'jwt-decode';

// const decode = <T>(cac: CAC): T => JSON.parse(atob(cac)) as T;
const decode = <T>(cac: CAC): T & { claimKey: string } => {
    const decodedToken = jwt_decode<T & { claimKey: string }>(cac);
    return {
        ...decodedToken,
        claimKey: decodedToken?.claimKey || 'https://api.dekopay.com',
    }
};

export default decode;
